.root {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 135px;
  margin: 5px auto 5px 0;
}

.profileImagePlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  flex-shrink: 0;
  color: #80868b;
  height: 30px;
  width: 30px;
  border: 1px solid #e8e8e8;
  margin-right: 5px;
}

.cardFrontImagePlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 10px;
  color: #80868b;
  height: 30px;
  width: 50px;
  border: 1px solid #e8e8e8;
  margin-left: 5px;
}

.profileImage {
  flex-shrink: 0;
  height: 30px;
  width: 30px;
  border: 1px solid #e8e8e8;
  margin-right: 5px;
}

.cardBlock {
  display: flex;
  width: 90px;
}

.card {
  margin: auto 0;
}
