.hoverWrapper {
  position: relative;
}

.hoverWrapper:hover .icon {
  cursor: pointer;
  display: block;
  z-index: 1;
}

.hoverWrapper .icon {
  position: absolute;
  display: none;
}

.hoverWrapper .icon {
  background-color: white;
  border-radius: 12px;
  margin: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  right: 0;
}
