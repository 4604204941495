.modalDialogWrapper {
  position: absolute;
  max-width: 500px;
  max-height: 400px;
  left: calc(50% - 250px);
  top: calc(50% - 200px);
  z-index: 100;
}

.modalDialog {
  padding: 0 20px;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.3);
  background-color: white;
  z-index: 100;
}

.modalDialog header {
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalDialog header p {
  font-family: 'Nunito Bold';
}

.modalDialog header .closeButton {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.modalDialog footer {
  padding: 15px 0;
  display: flex;
  justify-content: flex-end;
}

.modalDialog .content {
  box-sizing: border-box;
  min-width: 0px;
  min-height: 0px;
  max-height: 290px;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

@media (max-width: 560px) {
  .modalDialogWrapper {
    width: 100%;
    max-width: 100%;
    left: 0;
  }
}

.modalContainer {
  width: 300px;
  top: 0;
  left: auto;
  right: 0;
}

.modalContainer div {
  padding: 0;
}

.modalContainer .content {
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}

.modalContainer .content div,
.modalContainer .content svg {
  flex-shrink: 0;
}

.modalContainer header {
  padding-left: 20px;
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
