.root {
  position: absolute !important;
  height: fit-content !important;
}

.active {
  background-color: #3399ff57;
}

.active {
  background-color: #3399ff57;
}
