.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80%;
  height: 80%;
  margin: 10%;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.loader.smallWhite,
.loader.smallGrey {
  width: 20px;
  height: 20px;
}

.loader.smallWhite div {
  border: 2px solid #fff;
  border-color: #fff transparent transparent transparent;
}

.loader.smallGrey div {
  border: 2px solid #80868b;
  border-color: #80868b transparent transparent transparent;
}

.loader.list {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10rem;
  top: 0;
  left: 0;
  right: 0;
}

.loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
