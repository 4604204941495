.details .header {
  border-bottom: 1px solid #e8e8e8;
}

.details .attribute {
  display: flex;
  margin: 8px;
  width: 200px;
}

.details.details-edit .attribute {
  margin: 0 10px;
}

.details .attributes {
  max-width: 440px;
  margin: 5px 15px;
  display: flex;
  flex-flow: wrap;
}

.details .attribute {
  display: flex;
  flex-direction: column;
}

.details .attribute.attribute-name {
  width: 100%;
}

.details .attribute .value {
  font-family: 'Nunito Bold';
  overflow: hidden;
  text-overflow: ellipsis;
}

.details .attribute .type {
  font-size: 12px;
  color: #555;
}

.details .hubspot-settings-label,
.details .ad-import-label {
  font-family: 'Nunito Regular';
  margin-left: 20px;
  font-size: 16px;
}
