.infinity-menu-container {
  width: 100%;
}

.infinity-menu-display-tree-container ul {
  width: 100%;
  padding-inline-start: 0px;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-bottom: 1px;
}

.infinity-menu-node-container {
  text-align: center;
  display: flex;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  font-size: 18px;
}

.infinity-menu-node-container:hover {
  background-color: rgb(73, 73, 73);
}

.infinity-menu-leaf-container:hover {
  background-color: rgb(73, 73, 73);
}

.infinity-menu-load-more-container {
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: rgb(94, 96, 99);
  height: 30px;
  padding-left: 20px;
  padding-top: 15px;
  text-align: center;
  list-style-type: none;
}

.infinity-menu-load-more-container:hover {
  background-color: rgb(200, 200, 200);
}
