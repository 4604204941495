.root {
  cursor: pointer;
  width: 322px;
  height: 322px;
  display: flex;
  align-items: center;
  flex-flow: column;
  padding: 10px;
  margin: 15px;
}

.active {
  background-color: rgb(233 241 255);
  border-radius: 4px;
}

.frontSide {
  margin: auto;
}

.backSide {
  position: absolute;
  bottom: 25px;
  right: 25px;
}

.title {
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
}
