.sidenav {
  flex-direction: 'row';
  background-color: rgb(6, 16, 33);
  color: rgb(255, 255, 255);
  padding: 0;
}

.sidenav a {
  text-decoration: none;
  color: inherit;
}

.node {
  box-sizing: border-box;
  min-width: 0px;
  min-height: 0px;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  align-items: center;
  height: 40px;
  cursor: pointer;
  background-color: rgb(19, 28, 45);
}

.leaf {
  box-sizing: border-box;
  min-width: 0px;
  min-height: 0px;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  align-items: center;
  height: 40px;
  font-size: 11px;
  padding-left: 20px;
  background-color: rgb(19, 28, 44);
  cursor: pointer;

  font-size: 11px;
  padding-left: 20px;
}

.leaf.isOpen {
  background-color: rgb(42, 121, 255);
}

.footerLeaf {
  font-size: 12px;
  padding-left: 20px;
  font-weight: bold;
  border-top: 1px solid #2c3648;
  background-color: #061022;
}

.footerLeaf .text {
  color: #80868b;
}

.sidenav.smallSized .text {
  display: none;
}

.sidenav.smallSized .node {
  display: none;
}
