.root {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  background-color: white;
  height: 100%;
}

.main {
  width: 100%;
  height: calc(100% - 60px); /* height of Header 60px */
  background-color: white;
  background-image: radial-gradient(circle, #f1f1f1 15%, transparent 10%),
    radial-gradient(circle, #f1f1f1 15%, transparent 10%);
  background-size: 10px 10px;
  background-position: 0 0, 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frontRotate {
  animation: frontOpacityAnimation 2s linear;
}

.backRotate {
  animation: backOpacityAnimation 2s linear;
}

.loaderWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 220px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}

@keyframes frontOpacityAnimation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes backOpacityAnimation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
