.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid #e8e8e8;
  height: 60px;
  width: 100%;
  box-sizing: border-box;
}

.loaderWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 220px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.leftSide,
.rightSide {
  display: flex;
  align-items: center;
}

.leftSide {
  font-weight: 600;
}

.subTitle {
  font-weight: 400;
  margin-left: 5px;
}

.goBackIcon {
  cursor: pointer;
  margin-right: 20px;
}

.importBtn {
  margin-right: 10px;
}

.saveBtnIcon {
  margin-right: 10px;
  width: 12px;
  height: 12px;
}
