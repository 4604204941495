.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  user-select: none;
  position: relative;
  overflow: hidden;
}

.cardWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.card {
  position: relative;
  background-color: white;
  box-shadow: 1px 3px 6px rgba(194, 168, 168, 0.3);
  margin: 0 auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.activeGrid {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: repeating-linear-gradient(
      to bottom,
      #e6e6e675 0,
      #e6e6e675 1px,
      transparent 1px,
      transparent 3%
    ),
    repeating-linear-gradient(to right, #e6e6e675 0, #e6e6e675 1px, transparent 1px, transparent 3%);
}

.activeDraggableField {
  position: absolute;
  height: fit-content;
  outline: 1px solid transparent;
}
