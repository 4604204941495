.root {
  max-width: 280px;
  width: 100%;
  height: calc(100vh - 60px); /* 60px - Header height  */
  overflow-y: auto;
  padding: 15px;
  border-right: 1px solid #e8e8e8;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  cursor: pointer;
}

/* .root scrollbar START */
.root::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.root::-webkit-scrollbar-thumb {
  border: 0 none white;
  border-radius: 10px;
  background: #86898e;
}

.root::-webkit-scrollbar-thumb:active {
  background: #2d2f36;
}

.root::-webkit-scrollbar-track {
  border: 0 none white;
  border-radius: 0;
  background: transparent;
}

.root::-webkit-scrollbar-track:active {
  background: transparent;
}
/* .root scrollbar END */

.title {
  margin: 0 0 15px;
  font-size: 14px;
}

.draggableItem {
  margin-bottom: 10px;
  padding: 0 9px;
}

@media only screen and (max-width: 1700px) {
  .root {
    max-width: 255px;
  }
}
