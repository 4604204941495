.root {
  padding-top: 20px;
  max-width: 280px;
  width: 100%;
  height: calc(100vh - 60px); /* 60px - Header height  */
  border-left: 1px solid #e8e8e8;
  background-color: white;
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  overflow-y: auto;
}

/* .root scrollbar START */
.root::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.root::-webkit-scrollbar-thumb {
  border: 0 none white;
  border-radius: 10px;
  background: #86898e;
}

.root::-webkit-scrollbar-thumb:active {
  background: #2d2f36;
}

.root::-webkit-scrollbar-track {
  border: 0 none white;
  border-radius: 0;
  background: transparent;
}

.root::-webkit-scrollbar-track:active {
  background: transparent;
}
/* .root scrollbar END */

.closeBtn {
  position: absolute;
  right: 10px;
  top: 0;
}

.title {
  margin-bottom: 40px;
  padding-left: 20px;
  font-size: 14px;
}

.topSection {
  padding: 0 20px 30px;
  border-bottom: 1px solid #e8e8e8;
}

.bottomSection {
  padding: 30px 20px 0;
  margin-bottom: 15px;
}

.select,
.input {
  width: 100%;
}

@media only screen and (max-width: 1700px) {
  .root {
    max-width: 255px;
  }
}
