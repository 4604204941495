@font-face {
  font-family: 'Nunito SemiBold';
  src: url('/fonts/Nunito-SemiBold.ttf') format('woff2');
}

@font-face {
  font-family: 'Nunito Regular';
  src: url('/fonts/Nunito-Regular.ttf') format('woff2');
}

@font-face {
  font-family: 'Nunito ExtraBold';
  src: url('/fonts/Nunito-ExtraBold.ttf') format('woff2');
}

@font-face {
  font-family: 'Nunito SemiBold';
  src: url('/fonts/Nunito-SemiBold.ttf') format('woff2');
}

@font-face {
  font-family: 'Nunito Black';
  src: url('/fonts/Nunito-Black.ttf') format('woff2');
}

@font-face {
  font-family: 'Nunito Bold';
  src: url('/fonts/Nunito-Bold.ttf') format('woff2');
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
menu,
section,
main {
  display: block;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}

input,
button,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
