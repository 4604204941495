.root {
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
}

.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-size: 10px;
}

.field {
  display: flex;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
