.inputWrapper {
  display: flex;
  align-items: center;
  width: 100%;

  font-size: 12px;
  height: 30px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.inputWrapper input {
  outline: none;
  border: none;
  width: 100%;
  border-radius: 15px;
}

.inputWrapper input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #0000004d;
  opacity: 1; /* Firefox */
}

.inputWrapper input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #0000004d;
}

.inputWrapper input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #0000004d;
}

.icon {
  fill: #0000004d;
  padding: 0 10px;
}

.closeIcon {
  cursor: pointer;
  fill: #eb4444;
}
