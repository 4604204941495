.picture {
  display: flex;
  min-width: 120px;
  min-height: 120px;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
  background-color: white;
}

.contact-edit .picture {
  cursor: pointer;
}

.contact-edit .disabled{
  cursor: auto;
}

.picture.card {
  min-width: 200px;
}

.picture .initials {
  font-size: 64px;
  color: #80868b;
}

.picture .placeholder {
  display: flex;
  font-size: 10px;
  align-items: center;
}

.picture .placeholder svg {
  margin-right: 4px;
}
