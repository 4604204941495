.addBtn {
  position: relative;
}

.search {
  padding: 10px !important;
  width: calc(100% - 20px);
}

.employeesBlock {
  padding: 15px !important;
}

.employeeItem {
  display: flex;
  align-items: center;
  padding: 5px 0 !important;
  cursor: pointer;
}

.attachedEmployee {
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.attachedEmployee span {
  color: #eb4444;
}

.deleteEmployee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
