.root {
  border: 1px dashed #b3b3b3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  user-select: none;
}

.root:hover .closeIconWrapper {
  opacity: 1;
}

.textBlock {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 9px 0;
  white-space: nowrap;
  overflow: hidden;
}

.title {
  margin-right: 4px;
  line-height: 1 !important;
}

.originType {
  line-height: 1 !important;
}

.closeIconWrapper {
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s linear;
  position: absolute;
  right: 0;
  top: -15px;
}
