.noItemsContainer {
  display: inline-block;
  padding: 5rem 0;
  margin: 0 auto;
  text-align: center;
}

.noItemsContainer p {
  margin-bottom: 32px;
  font-size: 18px;
  color: #80868b;
}

.noItemsContainer button {
  display: flex;
  justify-content: center;
  padding: 32px 0;
  width: 100%;
  font-size: 16px;
  text-align: center;
  box-shadow: none;
  border: 1px solid #80868b;
  margin-bottom: 16px;
}

.noItemsContainer button span {
  color: #80868b;
}

.detailsPanel {
  border-left: 1px solid #e8e8e8;
}
