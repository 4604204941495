.selectionBox {
  cursor: pointer;
  height: 27px;
  max-width: 172px;
  font-size: 10px;
  color: #6aa1ff;
  border-top: 1px solid #6aa1ff;
  border-bottom: 1px solid #6aa1ff;
  display: flex;
  align-items: center;
}

.selectionBox > * {
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 14px;
  min-height: 29px;
  box-sizing: border-box;
  position: relative;
}

.selectionBox.createAccount *:last-child {
  border-top: 1px solid #6aa1ff;
  border-bottom: 1px solid #6aa1ff;
  border-right: 1px solid #6aa1ff;
  border-radius: 0 14px 14px 0;
}

.selectionBox.login *:first-child {
  border-top: 1px solid #6aa1ff;
  border-bottom: 1px solid #6aa1ff;
  border-left: 1px solid #6aa1ff;
  border-radius: 14px 0 0 14px;
}

.selectionBox.createAccount *:first-child,
.selectionBox.login *:last-child {
  background-color: #6aa1ff;
  color: white;
}

.selectionBox *:first-child {
  min-width: 130px;
}

.selectionBox *:last-child {
  min-width: 70px;
}

.loginForm {
  display: flex;
  flex-direction: column;
  width: 480px;
  height: 330px;
  background-color: white;
  font-size: 10px;
  margin: 30px 0 20px 0;
}

.loginForm .formControl .icon {
  cursor: pointer;
}

.loginForm .formControl {
  width: 100%;
}

.loginForm button:disabled {
  cursor: default;
  background-color: #eee;
}

.loginForm button .icon {
  margin-left: 8px;
}

.loginForm button:focus {
  outline: none;
}

.loginForm button {
  cursor: pointer;
  background-color: #2a79ff;
  border: none;
  border-radius: 15px;
  color: white;
  padding: 6px 18px;
  display: flex;
}

.loginForm a {
  margin-left: auto;
  padding-top: 8px;
  padding-right: 20px;
  margin-bottom: -26px;
  z-index: 1;
  font-size: 14px;
  color: #2a79ff;
  text-decoration: none;
}

.footer {
  flex: 1;
  display: flex;
  border-top: 1px solid #ddd;
  align-items: center;
  justify-content: flex-end;
  padding: 0 40px;
}

.title {
  padding: 20px 40px;
  font-size: 16px;
  font-family: 'Nunito Bold';
  border-bottom: 1px solid #ddd;
}

.inputWrapper {
  flex: 1;
  padding: 50px 20px;
}

.inputWrapper p {
  font-size: 12px;
  margin-top: 18px;
  margin-left: 20px;
  color: #555;
}

.inputWrapper p.error {
  color: red;
}

.resetPasswordForm .emailSentText p {
  font-size: 14px;
}
