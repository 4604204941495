@font-face {
  font-family: 'Nunito SemiBold';
  src: url('/fonts/Nunito-SemiBold.ttf') format('woff2');
}

@font-face {
  font-family: 'Nunito Regular';
  src: url('/fonts/Nunito-Regular.ttf') format('woff2');
}

@font-face {
  font-family: 'Nunito ExtraBold';
  src: url('/fonts/Nunito-ExtraBold.ttf') format('woff2');
}

@font-face {
  font-family: 'Nunito SemiBold';
  src: url('/fonts/Nunito-SemiBold.ttf') format('woff2');
}

@font-face {
  font-family: 'Nunito Black';
  src: url('/fonts/Nunito-Black.ttf') format('woff2');
}

@font-face {
  font-family: 'Nunito Bold';
  src: url('/fonts/Nunito-Bold.ttf') format('woff2');
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
menu,
section,
main {
  display: block;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
}

input,
button,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Login_selectionBox__1Hcoi {
  cursor: pointer;
  height: 27px;
  max-width: 172px;
  font-size: 10px;
  color: #6aa1ff;
  border-top: 1px solid #6aa1ff;
  border-bottom: 1px solid #6aa1ff;
  display: flex;
  align-items: center;
}

.Login_selectionBox__1Hcoi > * {
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 14px;
  min-height: 29px;
  box-sizing: border-box;
  position: relative;
}

.Login_selectionBox__1Hcoi.Login_createAccount__1MXgo *:last-child {
  border-top: 1px solid #6aa1ff;
  border-bottom: 1px solid #6aa1ff;
  border-right: 1px solid #6aa1ff;
  border-radius: 0 14px 14px 0;
}

.Login_selectionBox__1Hcoi.Login_login__3L4un *:first-child {
  border-top: 1px solid #6aa1ff;
  border-bottom: 1px solid #6aa1ff;
  border-left: 1px solid #6aa1ff;
  border-radius: 14px 0 0 14px;
}

.Login_selectionBox__1Hcoi.Login_createAccount__1MXgo *:first-child,
.Login_selectionBox__1Hcoi.Login_login__3L4un *:last-child {
  background-color: #6aa1ff;
  color: white;
}

.Login_selectionBox__1Hcoi *:first-child {
  min-width: 130px;
}

.Login_selectionBox__1Hcoi *:last-child {
  min-width: 70px;
}

.Login_loginForm__1MKFb {
  display: flex;
  flex-direction: column;
  width: 480px;
  height: 330px;
  background-color: white;
  font-size: 10px;
  margin: 30px 0 20px 0;
}

.Login_loginForm__1MKFb .Login_formControl__2lWaa .Login_icon__KCLO6 {
  cursor: pointer;
}

.Login_loginForm__1MKFb .Login_formControl__2lWaa {
  width: 100%;
}

.Login_loginForm__1MKFb button:disabled {
  cursor: default;
  background-color: #eee;
}

.Login_loginForm__1MKFb button .Login_icon__KCLO6 {
  margin-left: 8px;
}

.Login_loginForm__1MKFb button:focus {
  outline: none;
}

.Login_loginForm__1MKFb button {
  cursor: pointer;
  background-color: #2a79ff;
  border: none;
  border-radius: 15px;
  color: white;
  padding: 6px 18px;
  display: flex;
}

.Login_loginForm__1MKFb a {
  margin-left: auto;
  padding-top: 8px;
  padding-right: 20px;
  margin-bottom: -26px;
  z-index: 1;
  font-size: 14px;
  color: #2a79ff;
  text-decoration: none;
}

.Login_footer__2U8ZZ {
  flex: 1 1;
  display: flex;
  border-top: 1px solid #ddd;
  align-items: center;
  justify-content: flex-end;
  padding: 0 40px;
}

.Login_title__1xJp9 {
  padding: 20px 40px;
  font-size: 16px;
  font-family: 'Nunito Bold';
  border-bottom: 1px solid #ddd;
}

.Login_inputWrapper__2q2W_ {
  flex: 1 1;
  padding: 50px 20px;
}

.Login_inputWrapper__2q2W_ p {
  font-size: 12px;
  margin-top: 18px;
  margin-left: 20px;
  color: #555;
}

.Login_inputWrapper__2q2W_ p.Login_error__1WTa6 {
  color: red;
}

.Login_resetPasswordForm__v8GF_ .Login_emailSentText__3Psd1 p {
  font-size: 14px;
}

.components_loader__beUMO {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.components_loader__beUMO div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80%;
  height: 80%;
  margin: 10%;
  border: 8px solid #fff;
  border-radius: 50%;
  -webkit-animation: components_loader__beUMO 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: components_loader__beUMO 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.components_loader__beUMO.components_smallWhite__1ewq2,
.components_loader__beUMO.components_smallGrey__1vFIB {
  width: 20px;
  height: 20px;
}

.components_loader__beUMO.components_smallWhite__1ewq2 div {
  border: 2px solid #fff;
  border-color: #fff transparent transparent transparent;
}

.components_loader__beUMO.components_smallGrey__1vFIB div {
  border: 2px solid #80868b;
  border-color: #80868b transparent transparent transparent;
}

.components_loader__beUMO.components_list__2Aycs {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10rem;
  top: 0;
  left: 0;
  right: 0;
}

.components_loader__beUMO div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}

.components_loader__beUMO div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.components_loader__beUMO div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

@-webkit-keyframes components_loader__beUMO {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes components_loader__beUMO {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ModalDialog_modalDialogWrapper__29gm5 {
  position: absolute;
  max-width: 500px;
  max-height: 400px;
  left: calc(50% - 250px);
  top: calc(50% - 200px);
  z-index: 100;
}

.ModalDialog_modalDialog__14deJ {
  padding: 0 20px;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.3);
  background-color: white;
  z-index: 100;
}

.ModalDialog_modalDialog__14deJ header {
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ModalDialog_modalDialog__14deJ header p {
  font-family: 'Nunito Bold';
}

.ModalDialog_modalDialog__14deJ header .ModalDialog_closeButton__3usln {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.ModalDialog_modalDialog__14deJ footer {
  padding: 15px 0;
  display: flex;
  justify-content: flex-end;
}

.ModalDialog_modalDialog__14deJ .ModalDialog_content__3gKE9 {
  box-sizing: border-box;
  min-width: 0px;
  min-height: 0px;
  max-height: 290px;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

@media (max-width: 560px) {
  .ModalDialog_modalDialogWrapper__29gm5 {
    width: 100%;
    max-width: 100%;
    left: 0;
  }
}

.ModalDialog_modalContainer__3O-MH {
  width: 300px;
  top: 0;
  left: auto;
  right: 0;
}

.ModalDialog_modalContainer__3O-MH div {
  padding: 0;
}

.ModalDialog_modalContainer__3O-MH .ModalDialog_content__3gKE9 {
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}

.ModalDialog_modalContainer__3O-MH .ModalDialog_content__3gKE9 div,
.ModalDialog_modalContainer__3O-MH .ModalDialog_content__3gKE9 svg {
  flex-shrink: 0;
}

.ModalDialog_modalContainer__3O-MH header {
  padding-left: 20px;
}

.ModalDialog_btnContainer__q1WH6 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Card_root__1-l5Q {
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
}

.Card_placeholder__fdBn7 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-size: 10px;
}

.Card_field__2ZlF3 {
  display: flex;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.HoverWrapper_hoverWrapper__TjlgD {
  position: relative;
}

.HoverWrapper_hoverWrapper__TjlgD:hover .HoverWrapper_icon__3Oagb {
  cursor: pointer;
  display: block;
  z-index: 1;
}

.HoverWrapper_hoverWrapper__TjlgD .HoverWrapper_icon__3Oagb {
  position: absolute;
  display: none;
}

.HoverWrapper_hoverWrapper__TjlgD .HoverWrapper_icon__3Oagb {
  background-color: white;
  border-radius: 12px;
  margin: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  right: 0;
}

.contact .error {
  font-size: 14px;
  color: #feb300;
  font-family: 'Nunito Bold';
}

.contact .notes--edit-false {
  cursor: pointer;
}

.contact .notes .close-button {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.contact .notes > div {
  display: flex;
  overflow: hidden;
}

.contact .notes > div > pre {
  white-space: pre-wrap;
}

.contact .primary-attributes {
  display: flex;
  flex-direction: column;
}

.contact .primary-attributes {
  padding: 20px;
}

.contact .admin-settings {
  padding: 10px 20px 20px;
  background-color: rgba(0, 0, 0, 0.13);
}

.admin-settings header h2 {
  font-size: 14px !important;
}

.contact .secondary-attributes {
  overflow-y: auto;
}

.contact .pictures {
  display: flex;
  align-items: flex-end;
  overflow-x: auto;
  padding-bottom: 15px;
}

.contact .pictures .picture.profile {
  width: 120px;
  height: 120px;
}

.contact .pictures .picture.card {
  width: 200px;
  height: 120px;
}

.picture {
  margin-right: 15px;
}

.contact section {
  border-bottom: 1px solid #e8e8e8;
  padding: 20px;
}

.contact section header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
}

.contact section header h2 {
  color: #555;
  font-size: 16px;
  font-weight: normal;
}

.contact section .section-attributes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.contact .edit-contact-attribute {
  display: flex;
  min-height: 54px;
  align-items: start;
  min-width: 610px;
}

.contact .edit-contact-attribute-fields {
  max-width: 558px;
  display: flex;
  min-height: 54px;
  flex: 1 1;
}

.contact-you .primary-attributes input:-webkit-autofill,
.contact-you .primary-attributes input:-internal-autofill-selected {
  -webkit-text-fill-color: white;
}

.contact-you .header {
  background-color: #2a79ff;
  border-bottom: 1px solid #2263d3;
}

.contact-you .header p {
  color: white;
}

.contact-you .primary-attributes {
  background-color: #2a79ff;
}

.contact-detail .header {
  background-color: #f8f8f8;
  border-bottom: 1px solid #e8e8e8;
}

.contact-detail .header p {
  color: #80868b;
}

.contact-detail .header .close-button {
  fill: black;
}

.contact-you .header .close-button {
  fill: white;
}

.contact-detail .primary-attributes {
  background-color: #f8f8f8;
}

.contact .view-contact-attribute > * {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.contact .view-contact-attribute {
  display: flex;
  margin: 8px;
  width: 50%;
}

.contact .view-contact-attribute .icon {
  margin-right: 6px;
  flex-shrink: 0;
  align-self: center;
}

.contact .view-contact-attribute .value {
  font-family: 'Nunito Bold';
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact .view-contact-attribute a.value {
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.contact .view-contact-attribute a.value:hover {
  color: #2a79ff;
  text-decoration: underline;
}

.contact .view-contact-attribute .type {
  font-size: 12px;
  color: #555;
}

.contact .text-fields {
  padding-top: 20px;
}

.mr-15 {
  margin-right: 15px;
}

.hubspot-button {
  display: flex;
  align-items: center;
}

.picture {
  display: flex;
  min-width: 120px;
  min-height: 120px;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
  background-color: white;
}

.contact-edit .picture {
  cursor: pointer;
}

.contact-edit .disabled{
  cursor: auto;
}

.picture.card {
  min-width: 200px;
}

.picture .initials {
  font-size: 64px;
  color: #80868b;
}

.picture .placeholder {
  display: flex;
  font-size: 10px;
  align-items: center;
}

.picture .placeholder svg {
  margin-right: 4px;
}

.ReactTable {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.ReactTable * {
  box-sizing: border-box;
}
.ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}
.ReactTable .rt-thead {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}
.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}
.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}
.ReactTable .rt-thead .rt-tr {
  text-align: center;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}
.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-thead .rt-th:focus {
  outline: none;
}
.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}
.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}
.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px;
}
.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px;
}
.ReactTable .rt-tbody {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}
.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}
.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}
.ReactTable .rt-tr-group {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.ReactTable .rt-tr {
  flex: 1 0 auto;
  display: inline-flex;
}
.ReactTable .rt-th,
.ReactTable .rt-td {
  flex: 1 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}
.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}
.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}
.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}
.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
}
.ReactTable .rt-tfoot {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}
.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}
.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05);
}
.ReactTable .-pagination {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}
.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
}
.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
}
.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  flex: 1 1;
  text-align: center;
}
.ReactTable .-pagination .-center {
  flex: 1.5 1;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}
.ReactTable .-pagination .-pageJump {
  display: inline-block;
}
.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}
.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}
.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
}
.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.ReactTable .-loading > div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}
.ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.TableCellActions_actions__1Cdja {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;
}

.TableCellActions_action__25N32 {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.TableCellActions_action__25N32:first-child {
  margin-left: 0;
}

.TableCellActions_action__25N32 span {
  margin-left: 8px;
}

.TableCellActions_primary__2S4pm span {
  color: #eb4444;
}

.TableCellActions_limited__2Okyr {
  margin-right: 0;
}

.TableCellActions_limited__2Okyr span {
  display: none;
}

@media (max-width: 979px) {
  .TableCellActions_action__25N32 {
    margin-right: 0;
  }

  .TableCellActions_action__25N32 span {
    display: none;
  }
}

.Table_thead__RcTCR {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.Table_th__2s7YI {
  padding: 14px 20px;
}

.Table_th__2s7YI:last-child {
  display: flex;
  justify-content: flex-end;
}

.Table_td__2743C {
  padding: 5px 20px;
  min-height: 42px;
}

.Table_th__2s7YI,
.Table_td__2743C {
  display: flex;
  align-items: center;
  min-width: auto !important;
  width: auto;
  flex: 20 0 auto !important;
  font-size: 12px;
  text-align: left;
  font-weight: 400;

  @media (max-width: 1560px) {
    width: 100px !important;
  }
}

.Table_tableWrapper__QUdy- {
  overflow: auto;
}

.Table_tableWrapper__QUdy-.Table_fetching__2IroS {
  overflow: hidden;
}

/* Table borders */
.Table_table__1WOOw {
  border-spacing: 0;
  border-bottom: 1px solid #e8e8e8;
  border-left: 0;
  border-top: 0;
}

.Table_thead__RcTCR {
  border-top: 1px solid #e8e8e8;
}

.Table_td__2743C:last-child {
  border-right: 0;
}

.Table_tr__2QRFf:last-child .Table_td__2743C {
  border-bottom: 0;
}

.Table_th__2s7YI,
.Table_td__2743C {
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.Table_th__2s7YI:last-child,
.Table_td__2743C:last-child {
  border-right: 0;
}
/* Table borders */

.Table_resizer__2Cj3M {
  right: 0;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  touch-action: none;
}

.Table_th__2s7YI {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.Table_rowClickable__Zwpi_ {
  cursor: pointer;
}

.Table_rowClickable__Zwpi_:hover {
  background: rgba(42, 121, 255, 0.05);
}

.Table_rowClickable__Zwpi_:active {
  background: rgba(42, 121, 255, 0.1);
}

.Table_rowActive__2ZAxG {
  background: rgba(42, 121, 255, 0.05);
}

.Table_tableFooter__2OeAy {
  padding: 24px 0;
  text-align: center;
  font-size: 12px;
  color: #0000004d;
}

.Table_fetchMore__tSaPl {
  cursor: pointer;
}

.Table_noMore__27jSl {
  text-align: center;
}

.Table_sortIcon__2w2nn {
  margin-left: 10px;
}

.Table_tableLoader__fzVff {
  display: flex;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  padding-left: calc(50% - 110px - 80px / 2); /* 80px - size of the spinner */
}

.Table_table__1WOOw mark {
  background-color: #a5cdfe80;
}

.Table_resetScroll__1gTRE {
  cursor: pointer;
  position: fixed;
  padding: 12px;
  bottom: 24px;
  margin-left: 24px;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
  background: rgb(6, 16, 33);
  border-radius: 50%;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
}

.Table_resetScrollShow__3XLbF {
  opacity: 0.5;
}

.Table_resetScrollShow__3XLbF:hover {
  opacity: 0.8;
}

.TableCellFullName_root__2zOqX {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.TableCellFullName_name__2_cyf {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 135px;
  margin: 5px auto 5px 0;
}

.TableCellFullName_profileImagePlaceholder__bKjtU {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  flex-shrink: 0;
  color: #80868b;
  height: 30px;
  width: 30px;
  border: 1px solid #e8e8e8;
  margin-right: 5px;
}

.TableCellFullName_cardFrontImagePlaceholder__23YJb {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 10px;
  color: #80868b;
  height: 30px;
  width: 50px;
  border: 1px solid #e8e8e8;
  margin-left: 5px;
}

.TableCellFullName_profileImage__ymBmR {
  flex-shrink: 0;
  height: 30px;
  width: 30px;
  border: 1px solid #e8e8e8;
  margin-right: 5px;
}

.TableCellFullName_cardBlock__3zBts {
  display: flex;
  width: 90px;
}

.TableCellFullName_card__IvfuK {
  margin: auto 0;
}

.DebounceInput_inputWrapper__1XqLe {
  display: flex;
  align-items: center;
  width: 100%;

  font-size: 12px;
  height: 30px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.DebounceInput_inputWrapper__1XqLe input {
  outline: none;
  border: none;
  width: 100%;
  border-radius: 15px;
}

.DebounceInput_inputWrapper__1XqLe input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #0000004d;
  opacity: 1; /* Firefox */
}

.DebounceInput_inputWrapper__1XqLe input::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #0000004d;
  opacity: 1; /* Firefox */
}

.DebounceInput_inputWrapper__1XqLe input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #0000004d;
  opacity: 1; /* Firefox */
}

.DebounceInput_inputWrapper__1XqLe input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #0000004d;
}

.DebounceInput_inputWrapper__1XqLe input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #0000004d;
}

.DebounceInput_icon__1HFYj {
  fill: #0000004d;
  padding: 0 10px;
}

.DebounceInput_closeIcon__3G-bT {
  cursor: pointer;
  fill: #eb4444;
}

.TablePage_noItemsContainer__a6hNL {
  display: inline-block;
  padding: 5rem 0;
  margin: 0 auto;
  text-align: center;
}

.TablePage_noItemsContainer__a6hNL p {
  margin-bottom: 32px;
  font-size: 18px;
  color: #80868b;
}

.TablePage_noItemsContainer__a6hNL button {
  display: flex;
  justify-content: center;
  padding: 32px 0;
  width: 100%;
  font-size: 16px;
  text-align: center;
  box-shadow: none;
  border: 1px solid #80868b;
  margin-bottom: 16px;
}

.TablePage_noItemsContainer__a6hNL button span {
  color: #80868b;
}

.TablePage_detailsPanel__aRtfL {
  border-left: 1px solid #e8e8e8;
}

.ViewOfficeDetail_addBtn__TSkXE {
  position: relative;
}

.ViewOfficeDetail_search__1TfqB {
  padding: 10px !important;
  width: calc(100% - 20px);
}

.ViewOfficeDetail_employeesBlock__ZpO8r {
  padding: 15px !important;
}

.ViewOfficeDetail_employeeItem__36zmY {
  display: flex;
  align-items: center;
  padding: 5px 0 !important;
  cursor: pointer;
}

.ViewOfficeDetail_attachedEmployee__EUz0Z {
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.ViewOfficeDetail_attachedEmployee__EUz0Z span {
  color: #eb4444;
}

.ViewOfficeDetail_deleteEmployee__1pqI- {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.OrgCardDetail_tabItem__3F-8R {
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.OrgCardDetail_tabItem__3F-8R span {
  color: #eb4444;
}

.OrgCardDetail_actionsBlock__ScZ-Y {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.OrgCardDetail_actionsBlock__ScZ-Y > div {
  margin-left: 10px;
}

.OrgCardDetail_actionItem__1p3Z3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  flex-shrink: 0;
  outline: none;
}

.OrgCardDetail_actionItem__1p3Z3 > span {
  color: #000 !important;
}

.OrgCardDetail_actionItem__1p3Z3.OrgCardDetail_delete__2ghW_ > span {
  color: #eb4444 !important;
}

.OrgCardDetail_actionItem__1p3Z3:disabled {
  opacity: 0.5;
}

.OrgCardDetail_active__3Xe_p {
  background-color: #2dd955;
  color: #fff;
  padding: 3px 10px;
  font-size: 8px;
  border-radius: 10px;
}

.OrgCardDetail_loader__2GEo2 {
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}

.OrgCardDetail_headerBtnsBlock__138Y0 {
  position: relative;
  display: flex;
}

.OrgCardDetail_saveBtn__p8qie {
  background-color: #2dd955;
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 30px;
  padding: 0 20px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  align-items: center;
  outline: none;
}

.OrgCardDetail_saveBtn__p8qie:disabled {
  background-color: #bbb;
}

.OrgCardDetail_deleteCardBtn__3-afO {
  background-color: #eb4444;
  color: #fff;
}

.OrgCardItem_root__2Dda9 {
  cursor: pointer;
  width: 322px;
  height: 322px;
  display: flex;
  align-items: center;
  flex-flow: column;
  padding: 10px;
  margin: 15px;
}

.OrgCardItem_active__1rzhC {
  background-color: rgb(233 241 255);
  border-radius: 4px;
}

.OrgCardItem_frontSide__1kG_x {
  margin: auto;
}

.OrgCardItem_backSide__3FbLU {
  position: absolute;
  bottom: 25px;
  right: 25px;
}

.OrgCardItem_title__3vjua {
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
}

.OrgCards_root__2t2ib {
  background-color: white;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
}

.OrgCards_mainWrapper__3De9L {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  height: 100%;
}

.OrgCards_header__2yXZl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  height: 60px;
  padding: 0 20px;
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8;
}

.OrgCards_headerTitle__1By9k {
  font-size: 16px;
}

.OrgCards_cardsContainer__dO8Ou {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  background-color: white;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
}

.OrgCards_noCardsContainer__vKnp1 {
  display: block;
  padding: 5rem 0;
  margin: 0 auto;
  text-align: center;
}

.OrgCards_noCardsContainer__vKnp1 button {
  margin: 0 auto;
}

.OrgCards_detailsAside__38lAy {
  width: 40%;
  min-width: 400px;
  max-width: 500px;
  flex-shrink: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  box-sizing: border-box;
}

.OrgCards_loader__sQDTi {
  display: flex;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  padding-left: calc(50% - 110px - 80px / 2); /* 80px - size of the spinner */
  z-index: 10;
}

.OrgCards_snackBar__39kUu {
  position: absolute;
  bottom: 0;
  left: 220px;
  right: 0;
  display: flex;
  justify-content: center;
}

.ImageCropper_root__2OgUl {
  display: flex;
  flex-flow: column;
  position: relative;
}

.ImageCropper_btnBlock__2kx_R {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ImageCropper_cancelBtn__Gwbwt {
  margin: 0 10px;
}

.ImageCropper_leftBlock__1K8oT,
.ImageCropper_rightBlock__l8A1B {
  display: flex;
  font-size: 12px;
}

.ImageCropper_input__3aTkZ {
  width: 75px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background-color: white;
}

.ImageCropper_isActive__TE_Te {
  background-color: #2a79ff;
}

.ImageCropper_imageWrapper__1rBK2 {
  max-width: 850px;
  width: 100%;
  max-height: 505px;
  height: 505px;
  border-radius: 4px;
  overflow: hidden;
}

.ImageCropper_image__1i0kv {
  display: block;
  max-width: 100%;
}

@media only screen and (max-height: 600px) {
  .ImageCropper_imageWrapper__1rBK2 {
    max-height: 400px;
  }
}

.DraggableItem_root__3E757 {
  border: 1px dashed #b3b3b3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  box-sizing: border-box;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.DraggableItem_root__3E757:hover .DraggableItem_closeIconWrapper__2WtYn {
  opacity: 1;
}

.DraggableItem_textBlock__1U3Il {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 9px 0;
  white-space: nowrap;
  overflow: hidden;
}

.DraggableItem_title__1R-9U {
  margin-right: 4px;
  line-height: 1 !important;
}

.DraggableItem_originType__2t6eb {
  line-height: 1 !important;
}

.DraggableItem_closeIconWrapper__2WtYn {
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s linear;
  position: absolute;
  right: 0;
  top: -15px;
}

.ResizableCardField_root__1P4dp {
  position: absolute !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.ResizableCardField_active__2R-9N {
  background-color: #3399ff57;
}

.ResizableCardField_active__2R-9N {
  background-color: #3399ff57;
}

.FieldConfigBar_root__1UQe_ {
  padding-top: 20px;
  max-width: 280px;
  width: 100%;
  height: calc(100vh - 60px); /* 60px - Header height  */
  border-left: 1px solid #e8e8e8;
  background-color: white;
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  overflow-y: auto;
}

/* .root scrollbar START */
.FieldConfigBar_root__1UQe_::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.FieldConfigBar_root__1UQe_::-webkit-scrollbar-thumb {
  border: 0 none white;
  border-radius: 10px;
  background: #86898e;
}

.FieldConfigBar_root__1UQe_::-webkit-scrollbar-thumb:active {
  background: #2d2f36;
}

.FieldConfigBar_root__1UQe_::-webkit-scrollbar-track {
  border: 0 none white;
  border-radius: 0;
  background: transparent;
}

.FieldConfigBar_root__1UQe_::-webkit-scrollbar-track:active {
  background: transparent;
}
/* .root scrollbar END */

.FieldConfigBar_closeBtn__3qbX4 {
  position: absolute;
  right: 10px;
  top: 0;
}

.FieldConfigBar_title__gf0D3 {
  margin-bottom: 40px;
  padding-left: 20px;
  font-size: 14px;
}

.FieldConfigBar_topSection__1DJTl {
  padding: 0 20px 30px;
  border-bottom: 1px solid #e8e8e8;
}

.FieldConfigBar_bottomSection__3QRaW {
  padding: 30px 20px 0;
  margin-bottom: 15px;
}

.FieldConfigBar_select__1xsOy,
.FieldConfigBar_input__1opuv {
  width: 100%;
}

@media only screen and (max-width: 1700px) {
  .FieldConfigBar_root__1UQe_ {
    max-width: 255px;
  }
}

.FieldListBar_root__2e1me {
  max-width: 280px;
  width: 100%;
  height: calc(100vh - 60px); /* 60px - Header height  */
  overflow-y: auto;
  padding: 15px;
  border-right: 1px solid #e8e8e8;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  cursor: pointer;
}

/* .root scrollbar START */
.FieldListBar_root__2e1me::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.FieldListBar_root__2e1me::-webkit-scrollbar-thumb {
  border: 0 none white;
  border-radius: 10px;
  background: #86898e;
}

.FieldListBar_root__2e1me::-webkit-scrollbar-thumb:active {
  background: #2d2f36;
}

.FieldListBar_root__2e1me::-webkit-scrollbar-track {
  border: 0 none white;
  border-radius: 0;
  background: transparent;
}

.FieldListBar_root__2e1me::-webkit-scrollbar-track:active {
  background: transparent;
}
/* .root scrollbar END */

.FieldListBar_title__1JsWh {
  margin: 0 0 15px;
  font-size: 14px;
}

.FieldListBar_draggableItem__3pAyU {
  margin-bottom: 10px;
  padding: 0 9px;
}

@media only screen and (max-width: 1700px) {
  .FieldListBar_root__2e1me {
    max-width: 255px;
  }
}

.FileUploadButton_input__1ceSp {
  display: none;
}

.CardActionButtons_root__RctWx {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}

.CardActionButtons_uploadBtn__1jB0X {
  position: relative;
}

.CardActionButtons_flipOverBtn__3iZ-W {
  margin: 0 10px;
}

.CardActionButtons_gridBtn__1dVsz {
  margin-right: 10px;
  padding: 0 10px !important;
}

.CardActionButtons_removeBtn__3iny5 {
  padding: 0 10px !important;
}

.AddDataFields_root__yEpk4 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  overflow: hidden;
}

.AddDataFields_cardWrapper__yBD6O {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.AddDataFields_card__34TfX {
  position: relative;
  background-color: white;
  box-shadow: 1px 3px 6px rgba(194, 168, 168, 0.3);
  margin: 0 auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.AddDataFields_activeGrid__3aCNV {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: repeating-linear-gradient(
      to bottom,
      #e6e6e675 0,
      #e6e6e675 1px,
      transparent 1px,
      transparent 3%
    ),
    repeating-linear-gradient(to right, #e6e6e675 0, #e6e6e675 1px, transparent 1px, transparent 3%);
}

.AddDataFields_activeDraggableField__3i6bo {
  position: absolute;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  outline: 1px solid transparent;
}

.CardEditorHeader_root__3P_fZ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid #e8e8e8;
  height: 60px;
  width: 100%;
  box-sizing: border-box;
}

.CardEditorHeader_loaderWrapper__3YFvY {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 220px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.CardEditorHeader_leftSide__tvxrv,
.CardEditorHeader_rightSide__2fnZ4 {
  display: flex;
  align-items: center;
}

.CardEditorHeader_leftSide__tvxrv {
  font-weight: 600;
}

.CardEditorHeader_subTitle__lemC2 {
  font-weight: 400;
  margin-left: 5px;
}

.CardEditorHeader_goBackIcon__2M2TH {
  cursor: pointer;
  margin-right: 20px;
}

.CardEditorHeader_importBtn__1qkBO {
  margin-right: 10px;
}

.CardEditorHeader_saveBtnIcon__YXz9D {
  margin-right: 10px;
  width: 12px;
  height: 12px;
}

.CardEditor_root__1UU05 {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  background-color: white;
  height: 100%;
}

.CardEditor_main__KH3cu {
  width: 100%;
  height: calc(100% - 60px); /* height of Header 60px */
  background-color: white;
  background-image: radial-gradient(circle, #f1f1f1 15%, transparent 10%),
    radial-gradient(circle, #f1f1f1 15%, transparent 10%);
  background-size: 10px 10px;
  background-position: 0 0, 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CardEditor_frontRotate__3ppO2 {
  -webkit-animation: CardEditor_frontOpacityAnimation__3Kzoj 2s linear;
          animation: CardEditor_frontOpacityAnimation__3Kzoj 2s linear;
}

.CardEditor_backRotate__1YXXF {
  -webkit-animation: CardEditor_backOpacityAnimation__1DaPh 2s linear;
          animation: CardEditor_backOpacityAnimation__1DaPh 2s linear;
}

.CardEditor_loaderWrapper__11-Av {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 220px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}

@-webkit-keyframes CardEditor_frontOpacityAnimation__3Kzoj {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes CardEditor_frontOpacityAnimation__3Kzoj {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes CardEditor_backOpacityAnimation__1DaPh {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes CardEditor_backOpacityAnimation__1DaPh {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.List_actions__2E-jJ {
  margin-left: auto;
}

.List_action__28onY {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.List_action__28onY:first-child {
  margin-left: 0;
}

.List_action__28onY span {
  margin-left: 8px;
}

.List_primary__261HU span {
  color: #eb4444;
}

.List_listLoader__3FG04 {
  display: flex;
  align-items: center;
  position: fixed;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  padding-left: calc(50% - 110px - 80px / 2); /* 80px - size of the spinner */
}

.List_item__3a5YR {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #e8e8e8;
}

.List_item__3a5YR.List_active__1mcCy {
  background-color: #f8f8f8;
}

.List_listFooter__1Df3G {
  padding: 24px 0;
  text-align: center;
  font-size: 12px;
  color: #0000004d;
}

.List_fetchMore__2gaB_ {
  cursor: pointer;
}

.ADImport_adImport__2Ljc7 {
  display: flex;
  flex-direction: column;
  font-family: 'Nunito Regular';
}

.ADImport_adImport__2Ljc7 .ADImport_buttonSection__3WwHS {
  display: flex;
  flex-direction: row;
  align-items: center;
   font-size: 12px;
}

.ADImport_buttonSection__3WwHS .ADImport_importBtn__3gnPS {
  margin: 8px;
  margin-top: 0;
}

.ADImport_buttonSection__3WwHS .ADImport_warnMessage__1V9XH {
  margin: 8px;
  width: 225px;
}

.ADImport_adImport__2Ljc7 .ADImport_timeSection__KqDlp {
  display: flex;
  flex-direction: column;
  margin: 8px;
}

.ADImport_timeSection__KqDlp .ADImport_timeLabel__1RnOT {
  color: #555;
  font-size: 12px;
  margin-right: 8px;;
}

.ADImport_timeSection__KqDlp .ADImport_timeValue__3jpWQ {
  font-family: 'Nunito Bold';
}
.details .header {
  border-bottom: 1px solid #e8e8e8;
}

.details .attribute {
  display: flex;
  margin: 8px;
  width: 200px;
}

.details.details-edit .attribute {
  margin: 0 10px;
}

.details .attributes {
  max-width: 440px;
  margin: 5px 15px;
  display: flex;
  flex-flow: wrap;
}

.details .attribute {
  display: flex;
  flex-direction: column;
}

.details .attribute.attribute-name {
  width: 100%;
}

.details .attribute .value {
  font-family: 'Nunito Bold';
  overflow: hidden;
  text-overflow: ellipsis;
}

.details .attribute .type {
  font-size: 12px;
  color: #555;
}

.details .hubspot-settings-label,
.details .ad-import-label {
  font-family: 'Nunito Regular';
  margin-left: 20px;
  font-size: 16px;
}

.Main_sidenav__1jpom {
  flex-direction: 'row';
  background-color: rgb(6, 16, 33);
  color: rgb(255, 255, 255);
  padding: 0;
}

.Main_sidenav__1jpom a {
  text-decoration: none;
  color: inherit;
}

.Main_node__-Iuhw {
  box-sizing: border-box;
  min-width: 0px;
  min-height: 0px;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  align-items: center;
  height: 40px;
  cursor: pointer;
  background-color: rgb(19, 28, 45);
}

.Main_leaf__3kWqU {
  box-sizing: border-box;
  min-width: 0px;
  min-height: 0px;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  align-items: center;
  height: 40px;
  font-size: 11px;
  padding-left: 20px;
  background-color: rgb(19, 28, 44);
  cursor: pointer;

  font-size: 11px;
  padding-left: 20px;
}

.Main_leaf__3kWqU.Main_isOpen__E2_NS {
  background-color: rgb(42, 121, 255);
}

.Main_footerLeaf__EXYFA {
  font-size: 12px;
  padding-left: 20px;
  font-weight: bold;
  border-top: 1px solid #2c3648;
  background-color: #061022;
}

.Main_footerLeaf__EXYFA .Main_text__2THnz {
  color: #80868b;
}

.Main_sidenav__1jpom.Main_smallSized__2CGn3 .Main_text__2THnz {
  display: none;
}

.Main_sidenav__1jpom.Main_smallSized__2CGn3 .Main_node__-Iuhw {
  display: none;
}

.infinity-menu-container {
  width: 100%;
}

.infinity-menu-display-tree-container ul {
  width: 100%;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
  margin-bottom: 1px;
}

.infinity-menu-node-container {
  text-align: center;
  display: flex;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  font-size: 18px;
}

.infinity-menu-node-container:hover {
  background-color: rgb(73, 73, 73);
}

.infinity-menu-leaf-container:hover {
  background-color: rgb(73, 73, 73);
}

.infinity-menu-load-more-container {
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: rgb(94, 96, 99);
  height: 30px;
  padding-left: 20px;
  padding-top: 15px;
  text-align: center;
  list-style-type: none;
}

.infinity-menu-load-more-container:hover {
  background-color: rgb(200, 200, 200);
}

.codeInvalidContainer {
  width: 315px;
  height: 145px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.082);
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  transition: background-color 5000s ease-in-out 0s;
}

.button {
  display: flex;
  font-size: 12px;
  height: 30px;
  padding: 0 20px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  align-items: center;
  outline: none;
}

.button:focus {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
}

.button:disabled svg {
  fill: #bbb;
}

.button:disabled,
.button.white:disabled,
.button.warn:disabled svg,
.button.green:disabled svg,
.button.blueText:disabled svg,
.button.blue:disabled,
.button.hubspot:disabled {
  color: #bbb;
  background-color: #eee;
  border: none;
  box-shadow: none;
  cursor: default;
}

.button:disabled svg,
.button.white:disabled svg,
.button.warn:disabled svg,
.button.green:disabled svg,
.button.blueText:disabled svg,
.button.blue:disabled svg {
  fill: #bbb;
}

.button.blue {
  color: white;
  background-color: #2a79ff;
}

.button.blue svg {
  fill: white;
}

.button.blueText {
  color: #2a79ff;
  background-color: white;
}

.button.blueText svg {
  fill: #2a79ff;
}

.button.warn {
  color: tomato;
  background-color: white;
}

.button.warn svg {
  fill: tomato;
}

.button.green {
  color: white;
  background-color: #2dd955;
}

.button.green:disabled {
  background-color: #bbb;
}

.button.green svg {
  fill: white;
}

.button.white {
  color: black;
  background-color: white;
}

.button.hubspot {
  color: white;
  background-color: #FF7A59;
}

.loader-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.loader-wrapper-black {
  background: rgba(0, 0, 0, 0.3);
}

.md-snackbar {
  display: flex;
  align-items: center;
}

.md-snackbar .md-btn {
  margin-left: 10px;
  border: none;
  background: transparent;
  color: white;
  outline: none;
  cursor: pointer;
}

.snakbar-container {
  position: absolute;
  justify-content: center;
  bottom: 0px;
  right: 0px;
  width: 100%;
  display: flex;
}

.panel {
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: white;
  display: flex;
}

.panel p {
  margin: 0;
}

.panel .header {
  display: flex;
  min-height: 60px;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
}

.panel .header p {
  font-size: 16px;
  font-family: 'Nunito Bold';
}

.panel .header-buttons {
  display: flex;
  align-items: center;
}

.panel .header .close-button {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

