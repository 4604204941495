.actions {
  margin-left: auto;
}

.action {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.action:first-child {
  margin-left: 0;
}

.action span {
  margin-left: 8px;
}

.primary span {
  color: #eb4444;
}

.listLoader {
  display: flex;
  align-items: center;
  position: fixed;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  padding-left: calc(50% - 110px - 80px / 2); /* 80px - size of the spinner */
}

.item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #e8e8e8;
}

.item.active {
  background-color: #f8f8f8;
}

.listFooter {
  padding: 24px 0;
  text-align: center;
  font-size: 12px;
  color: #0000004d;
}

.fetchMore {
  cursor: pointer;
}
