.tabItem {
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.tabItem span {
  color: #eb4444;
}

.actionsBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionsBlock > div {
  margin-left: 10px;
}

.actionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  flex-shrink: 0;
  outline: none;
}

.actionItem > span {
  color: #000 !important;
}

.actionItem.delete > span {
  color: #eb4444 !important;
}

.actionItem:disabled {
  opacity: 0.5;
}

.active {
  background-color: #2dd955;
  color: #fff;
  padding: 3px 10px;
  font-size: 8px;
  border-radius: 10px;
}

.loader {
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}

.headerBtnsBlock {
  position: relative;
  display: flex;
}

.saveBtn {
  background-color: #2dd955;
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 30px;
  padding: 0 20px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  align-items: center;
  outline: none;
}

.saveBtn:disabled {
  background-color: #bbb;
}

.deleteCardBtn {
  background-color: #eb4444;
  color: #fff;
}
