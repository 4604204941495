.root {
  display: flex;
  flex-flow: column;
  position: relative;
}

.btnBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cancelBtn {
  margin: 0 10px;
}

.leftBlock,
.rightBlock {
  display: flex;
  font-size: 12px;
}

.input {
  width: 75px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background-color: white;
}

.isActive {
  background-color: #2a79ff;
}

.imageWrapper {
  max-width: 850px;
  width: 100%;
  max-height: 505px;
  height: 505px;
  border-radius: 4px;
  overflow: hidden;
}

.image {
  display: block;
  max-width: 100%;
}

@media only screen and (max-height: 600px) {
  .imageWrapper {
    max-height: 400px;
  }
}
