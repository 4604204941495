.contact .error {
  font-size: 14px;
  color: #feb300;
  font-family: 'Nunito Bold';
}

.contact .notes--edit-false {
  cursor: pointer;
}

.contact .notes .close-button {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.contact .notes > div {
  display: flex;
  overflow: hidden;
}

.contact .notes > div > pre {
  white-space: pre-wrap;
}

.contact .primary-attributes {
  display: flex;
  flex-direction: column;
}

.contact .primary-attributes {
  padding: 20px;
}

.contact .admin-settings {
  padding: 10px 20px 20px;
  background-color: rgba(0, 0, 0, 0.13);
}

.admin-settings header h2 {
  font-size: 14px !important;
}

.contact .secondary-attributes {
  overflow-y: auto;
}

.contact .pictures {
  display: flex;
  align-items: flex-end;
  overflow-x: auto;
  padding-bottom: 15px;
}

.contact .pictures .picture.profile {
  width: 120px;
  height: 120px;
}

.contact .pictures .picture.card {
  width: 200px;
  height: 120px;
}

.picture {
  margin-right: 15px;
}

.contact section {
  border-bottom: 1px solid #e8e8e8;
  padding: 20px;
}

.contact section header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
}

.contact section header h2 {
  color: #555;
  font-size: 16px;
  font-weight: normal;
}

.contact section .section-attributes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.contact .edit-contact-attribute {
  display: flex;
  min-height: 54px;
  align-items: start;
  min-width: 610px;
}

.contact .edit-contact-attribute-fields {
  max-width: 558px;
  display: flex;
  min-height: 54px;
  flex: 1;
}

.contact-you .primary-attributes input:-webkit-autofill,
.contact-you .primary-attributes input:-internal-autofill-selected {
  -webkit-text-fill-color: white;
}

.contact-you .header {
  background-color: #2a79ff;
  border-bottom: 1px solid #2263d3;
}

.contact-you .header p {
  color: white;
}

.contact-you .primary-attributes {
  background-color: #2a79ff;
}

.contact-detail .header {
  background-color: #f8f8f8;
  border-bottom: 1px solid #e8e8e8;
}

.contact-detail .header p {
  color: #80868b;
}

.contact-detail .header .close-button {
  fill: black;
}

.contact-you .header .close-button {
  fill: white;
}

.contact-detail .primary-attributes {
  background-color: #f8f8f8;
}

.contact .view-contact-attribute > * {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.contact .view-contact-attribute {
  display: flex;
  margin: 8px;
  width: 50%;
}

.contact .view-contact-attribute .icon {
  margin-right: 6px;
  flex-shrink: 0;
  align-self: center;
}

.contact .view-contact-attribute .value {
  font-family: 'Nunito Bold';
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact .view-contact-attribute a.value {
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.contact .view-contact-attribute a.value:hover {
  color: #2a79ff;
  text-decoration: underline;
}

.contact .view-contact-attribute .type {
  font-size: 12px;
  color: #555;
}

.contact .text-fields {
  padding-top: 20px;
}

.mr-15 {
  margin-right: 15px;
}

.hubspot-button {
  display: flex;
  align-items: center;
}
