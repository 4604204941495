.thead {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.th {
  padding: 14px 20px;
}

.th:last-child {
  display: flex;
  justify-content: flex-end;
}

.td {
  padding: 5px 20px;
  min-height: 42px;
}

.th,
.td {
  display: flex;
  align-items: center;
  min-width: auto !important;
  width: auto;
  flex: 20 0 auto !important;
  font-size: 12px;
  text-align: left;
  font-weight: 400;

  @media (max-width: 1560px) {
    width: 100px !important;
  }
}

.tableWrapper {
  overflow: auto;
}

.tableWrapper.fetching {
  overflow: hidden;
}

/* Table borders */
.table {
  border-spacing: 0;
  border-bottom: 1px solid #e8e8e8;
  border-left: 0;
  border-top: 0;
}

.thead {
  border-top: 1px solid #e8e8e8;
}

.td:last-child {
  border-right: 0;
}

.tr:last-child .td {
  border-bottom: 0;
}

.th,
.td {
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.th:last-child,
.td:last-child {
  border-right: 0;
}
/* Table borders */

.resizer {
  right: 0;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  touch-action: none;
}

.th {
  user-select: none;
  cursor: pointer;
}

.rowClickable {
  cursor: pointer;
}

.rowClickable:hover {
  background: rgba(42, 121, 255, 0.05);
}

.rowClickable:active {
  background: rgba(42, 121, 255, 0.1);
}

.rowActive {
  background: rgba(42, 121, 255, 0.05);
}

.tableFooter {
  padding: 24px 0;
  text-align: center;
  font-size: 12px;
  color: #0000004d;
}

.fetchMore {
  cursor: pointer;
}

.noMore {
  text-align: center;
}

.sortIcon {
  margin-left: 10px;
}

.tableLoader {
  display: flex;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  padding-left: calc(50% - 110px - 80px / 2); /* 80px - size of the spinner */
}

.table mark {
  background-color: #a5cdfe80;
}

.resetScroll {
  cursor: pointer;
  position: fixed;
  padding: 12px;
  bottom: 24px;
  margin-left: 24px;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
  background: rgb(6, 16, 33);
  border-radius: 50%;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
}

.resetScrollShow {
  opacity: 0.5;
}

.resetScrollShow:hover {
  opacity: 0.8;
}
