.actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;
}

.action {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.action:first-child {
  margin-left: 0;
}

.action span {
  margin-left: 8px;
}

.primary span {
  color: #eb4444;
}

.limited {
  margin-right: 0;
}

.limited span {
  display: none;
}

@media (max-width: 979px) {
  .action {
    margin-right: 0;
  }

  .action span {
    display: none;
  }
}
