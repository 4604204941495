.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}

.uploadBtn {
  position: relative;
}

.flipOverBtn {
  margin: 0 10px;
}

.gridBtn {
  margin-right: 10px;
  padding: 0 10px !important;
}

.removeBtn {
  padding: 0 10px !important;
}
