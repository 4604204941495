input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  transition: background-color 5000s ease-in-out 0s;
}

.button {
  display: flex;
  font-size: 12px;
  height: 30px;
  padding: 0 20px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  align-items: center;
  outline: none;
}

.button:focus {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
}

.button:disabled svg {
  fill: #bbb;
}

.button:disabled,
.button.white:disabled,
.button.warn:disabled svg,
.button.green:disabled svg,
.button.blueText:disabled svg,
.button.blue:disabled,
.button.hubspot:disabled {
  color: #bbb;
  background-color: #eee;
  border: none;
  box-shadow: none;
  cursor: default;
}

.button:disabled svg,
.button.white:disabled svg,
.button.warn:disabled svg,
.button.green:disabled svg,
.button.blueText:disabled svg,
.button.blue:disabled svg {
  fill: #bbb;
}

.button.blue {
  color: white;
  background-color: #2a79ff;
}

.button.blue svg {
  fill: white;
}

.button.blueText {
  color: #2a79ff;
  background-color: white;
}

.button.blueText svg {
  fill: #2a79ff;
}

.button.warn {
  color: tomato;
  background-color: white;
}

.button.warn svg {
  fill: tomato;
}

.button.green {
  color: white;
  background-color: #2dd955;
}

.button.green:disabled {
  background-color: #bbb;
}

.button.green svg {
  fill: white;
}

.button.white {
  color: black;
  background-color: white;
}

.button.hubspot {
  color: white;
  background-color: #FF7A59;
}

.loader-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.loader-wrapper-black {
  background: rgba(0, 0, 0, 0.3);
}

.md-snackbar {
  display: flex;
  align-items: center;
}

.md-snackbar .md-btn {
  margin-left: 10px;
  border: none;
  background: transparent;
  color: white;
  outline: none;
  cursor: pointer;
}

.snakbar-container {
  position: absolute;
  justify-content: center;
  bottom: 0px;
  right: 0px;
  width: 100%;
  display: flex;
}

.panel {
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: white;
  display: flex;
}

.panel p {
  margin: 0;
}

.panel .header {
  display: flex;
  min-height: 60px;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
}

.panel .header p {
  font-size: 16px;
  font-family: 'Nunito Bold';
}

.panel .header-buttons {
  display: flex;
  align-items: center;
}

.panel .header .close-button {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
