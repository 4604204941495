.adImport {
  display: flex;
  flex-direction: column;
  font-family: 'Nunito Regular';
}

.adImport .buttonSection {
  display: flex;
  flex-direction: row;
  align-items: center;
   font-size: 12px;
}

.buttonSection .importBtn {
  margin: 8px;
  margin-top: 0;
}

.buttonSection .warnMessage {
  margin: 8px;
  width: 225px;
}

.adImport .timeSection {
  display: flex;
  flex-direction: column;
  margin: 8px;
}

.timeSection .timeLabel {
  color: #555;
  font-size: 12px;
  margin-right: 8px;;
}

.timeSection .timeValue {
  font-family: 'Nunito Bold';
}