.root {
  background-color: white;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
}

.mainWrapper {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  height: 60px;
  padding: 0 20px;
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8;
}

.headerTitle {
  font-size: 16px;
}

.cardsContainer {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  background-color: white;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
}

.noCardsContainer {
  display: block;
  padding: 5rem 0;
  margin: 0 auto;
  text-align: center;
}

.noCardsContainer button {
  margin: 0 auto;
}

.detailsAside {
  width: 40%;
  min-width: 400px;
  max-width: 500px;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  right: 0;
  box-sizing: border-box;
}

.loader {
  display: flex;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  padding-left: calc(50% - 110px - 80px / 2); /* 80px - size of the spinner */
  z-index: 10;
}

.snackBar {
  position: absolute;
  bottom: 0;
  left: 220px;
  right: 0;
  display: flex;
  justify-content: center;
}
